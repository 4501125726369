/* eslint-disable vue/no-v-html */
<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <h2 class="brand-text ml-1">
          <PnxLogo />
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto register-page"
        >
          <b-card-title class="mb-1">
            {{ $t('registration.welcome') }} Phoenix Native 🚀
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('registration.signup-msg') }}
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="registerForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="pnxRegister"
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="register-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="john@example.com"
                    autocomplete="username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- username -->
              <b-form-group
                :label="$t('profile.usertitle')"
                label-for="register-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  vid="username"
                  rules="required"
                >
                  <b-form-input
                    id="register-username"
                    v-model="username"
                    name="register-username"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('profile.nameexample')"
                    autocomplete="username"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <validation-provider
                #default="{ errors }"
                :name="$t('stats.country')"
                rules="required"
              >
                <b-form-group
                  :label="$t('stats.country')"
                  label-for="i-country"
                  :class="errors.length == 0 || 'is-invalid'"
                >
                  <v-select
                    id="i-country"
                    v-model="country"
                    :placeholder="$t('sites.choosecountry')"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="target_countries"
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <!-- currency -->
              <validation-provider
                #default="{ errors }"
                name="currency"
                rules="required"
              >
                <b-form-group
                  :label="$t('registration.accountcurrency')"
                  label-for="i-currency"
                  :class="errors.length == 0 || 'is-invalid'"
                >
                  <v-select
                    id="i-currency"
                    v-model="currency"
                    :placeholder="$t('registration.selectcurrency')"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="currencyList"
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <!-- phone -->
              <b-form-group
                :label="$t('profile.phoneexample')"
                label-for="register-phone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Phone"
                  vid="phone"
                  rules="required"
                >
                  <b-form-input
                    id="register-phone"
                    v-model="phone"
                    name="register-phone"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('profile.phoneexample')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                :label="$t('page.contacts.title')"
              >
                <div
                  v-for="(contact, index) in contacts"
                  :key="index"
                  class="contacts mb-1"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="socials[contact.type].text"
                    rules="required"
                  >
                    <b-input-group
                      :class="{
                        'is-invalid': errors.length > 0,
                      }"
                    >
                      <b-input-group-prepend>
                        <b-dropdown
                          variant="outline-secondary"
                          class=""
                        >
                          <template #button-content>
                            <b-img
                              width="16"
                              height="16"
                              class="align-middle"
                              :src="socials[contact.type].src"
                            />
                          </template>
                          <b-dropdown-item
                            v-for="(key, idx) in Object.keys(socials)"
                            :key="idx"
                            @click="contactTypeUpdate(key, index)"
                          >
                            <b-img
                              width="19"
                              height="19"
                              :src="socials[key].src"
                            /> {{ socials[key].text }}
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="contacts[index].value"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="socials[contact.type].text"
                      />
                      <b-input-group-append>
                        <b-button
                          v-if="index === 0"
                          variant="outline-primary"
                          @click="contactAdd()"
                        >
                          <feather-icon
                            icon="PlusIcon"
                            class="cursor-pointer"
                          />
                        </b-button>
                        <b-button
                          v-if="index > 0"
                          variant="outline-danger"
                          @click="contactRemove(index)"
                        >
                          <feather-icon
                            icon="TrashIcon"
                            class="cursor-pointer"
                          />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label-for="register-password"
                :label="$t('registration.password')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      placeholder="············"
                      autocomplete="new-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                :label="$t('registration.confirmpassword')"
                label-for="i-c-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Re-type Password"
                  rules="required|confirmed:password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length == 0 || 'is-invalid'"
                  >
                    <b-form-input
                      id="i-c-password"
                      v-model="RetypePassword"
                      :state="errors.length > 0 ? false:null"
                      :type="passwordFieldTypeRetype"
                      placeholder="············"
                      autocomplete="confirm-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconRetype"
                        class="cursor-pointer"
                        @click="togglePasswordRetype"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('privacypolicy.title')"
                  :rules="{ required: { allowFalse: false } }"
                >
                  <b-form-checkbox
                    id="register-privacy-policy"
                    v-model="iagreeto"
                    :state="errors.length > 0 ? false:null"
                    name="iagreeto"
                  >
                    {{ $t('privacypolicy.iagreeto') }}
                    <b-link v-b-modal.modal-privacypolicy>
                      {{ $t('privacypolicy.link_text') }}
                    </b-link>
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid"
              >
                {{ $t('registration.signup') }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>{{ $t('registration.haveaccount') }}?</span>
            <b-link :to="{name:'auth-login'}">
              <span>&nbsp;{{ $t('registration.signin') }}</span>
            </b-link>
            <b-navbar-nav class="nav align-items-center mt-2">
              <locale />
            </b-navbar-nav>
          </p>
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
    <b-modal
      id="modal-privacypolicy"
      ref="modal-pp"
      scrollable
    >
      <template #modal-title>
        {{ $t('privacypolicy.title') }}
      </template>
      <template
        #default
      >
        <privatpolicyText />
      </template>
      <template #modal-footer>
        <b-button
          variant="outline-secondary"
          @click="cencel()"
        >
          {{ $t('form.cancel') }}
        </b-button>
        <b-button
          variant="primary"
          @click="ok()"
        >
          {{ $t('Accept') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import options from '@/libs/options'
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BModal,
  VBModal,
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BImg,
  BCardTitle,
  BCardText,
  BNavbarNav,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import PnxLogo from '@/views/ui/Pnx.vue'
import vSelect from 'vue-select'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import Ripple from 'vue-ripple-directive'
import privatpolicyText from './privacyPolicyText.vue'

export default {
  components: {
    BModal,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    PnxLogo,
    vSelect,
    Locale,
    BNavbarNav,
    privatpolicyText,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      iagreeto: false,
      status: '',
      username: '',
      userEmail: '',
      skype: '',
      phone: '',
      ...options,
      currency: null,
      country: null,
      password: '',
      RetypePassword: '',
      passwordFieldType: 'password',
      passwordFieldTypeRetype: 'password',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
      affiliate: '',
      socials: {
        telegram: {
          src: require('@/assets/images/icons/telegram-brands.svg'),
          text: 'Telegram',
        },
        whatsupp: {
          src: require('@/assets/images/icons/whatsapp-brands.svg'),
          text: 'Whatsapp',
        },
        viber: {
          src: require('@/assets/images/icons/viber-brands.svg'),
          text: 'Viber',
        },
        skype: {
          src: require('@/assets/images/icons/skype-brands.svg'),
          text: 'Skype',
        },
        other: {
          src: require('@/assets/images/icons/speaker.svg'),
          text: 'Other',
        },
      },
      contacts: [
        { type: 'telegram', value: '' },
      ],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    privacypolicyText() {
      return this.$t('privacypolicy.content').join('')
    },
  },
  methods: {
    ok() {
      this.iagreeto = true
      this.$refs['modal-pp'].hide()
    },
    cencel() {
      this.iagreeto = false
      this.$refs['modal-pp'].hide()
    },
    async pnxRegister() {
      this.$refs.registerForm.validate().then(async success => {
        if (success) {
          const urlSearchParams = new URLSearchParams(window.location.search)
          const urlParams = Object.fromEntries(urlSearchParams.entries())
          if (urlParams.ref) {
            this.affiliate = urlParams.ref
          }

          const account = {
            affiliate: this.affiliate,
            email: this.userEmail,
            name: this.username,
            password: this.password,
            phone: this.phone,
            balance: 0,
            is_admin: false,
            created_at: null,
            currency: this.currency.value,
            owns: null,
            is_agency_member: false,
            is_agency: false,
            roles: [],
            show_amount: false,
            country: this.country !== null ? this.country.value : null,
            contacts: this.contacts,
            notes: '',
          }

          await useJwt.register({ account }).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Register',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'You have successfully register',
              },
            })
            this.$refs.registerForm.reset()
            this.$router.push('/login')
          }).catch(error => {
            if (error.response && error.response.status === 422) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: error.response.data.errors[0].detail,
                },
              })
              this.$refs.registerForm.setErrors({ email: error.response.data.errors[0].detail })
            }
          })
        }
      })
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    contactAdd() {
      this.contacts.push({ type: 'telegram', value: '' })
    },
    contactTypeUpdate(type, index) {
      this.contacts[index].type = type
    },
    contactRemove(id) {
      this.contacts.splice(id, 1)
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
